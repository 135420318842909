@tailwind base;
@tailwind components;
@tailwind utilities !important;

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: monospace;
}
