.card {
    max-width: 60vw;
    min-height: 40vh;
    margin: auto;
    border-radius: 25px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1em;
}

.box-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    align-content: center;
    margin: auto;
}